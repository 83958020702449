import React, { useRef, useState, useEffect } from "react"
import { svgAsPngUri } from "save-svg-as-png"
import timestamp from "time-stamp"
import SaveIndicator from "./SaveIndicator"

const SvgMosaicGrid = props => {
  const { colorSet, height } = props

  const image = {
    w: 400,
    h: 500
  }

  const SvgElement = useRef(null)
  const [isHover, setHover] = useState(false)
  const [svgData, setSvgData] = useState("")
  const [id, setId] = useState("")
  const fileName = `mosaic-grid-${id}.png`

  useEffect(() => {
    const svg = SvgElement.current
    svgAsPngUri(svg, { fileName }).then(uri => {
      setSvgData(uri)
    })
  })

  const handleMouseEnter = () => setHover(true)
  const handleMouseLeave = () => setHover(false)
  const handleOnClick = () => {
    setId(`${timestamp("YYYY-MM-DD_HH-mm-ss")}`)
    setHover(false)
  }

  return (
    <a
      className='wrapper-link'
      href={svgData}
      download={fileName}
      onFocus={handleMouseEnter}
      onBlur={handleMouseLeave}
    >
      <div
        className='color-grid mosaic-color-grid'
        style={{ height: height }}
        onClick={handleOnClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <SaveIndicator visible={isHover} />
        <svg
          xmlns='http://www.w3.org/2000/svg'
          xmlnsse='http://svg-edit.googlecode.com'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          id='mosaicColorGrid'
          className='mosaic-color-grid-svg'
          viewBox={`0 0 ${image.w} ${image.h}`}
          height={height}
          width='100%'
          preserveAspectRatio='none'
          ref={SvgElement}
        >
          <g>
            <rect
              x={0}
              y={0}
              width={"33.4%"}
              height={"100%"}
              fill={colorSet[0]}
            />
            <rect
              x={"33.333333%"}
              y={0}
              width={"33.5%"}
              height={"100%"}
              fill={colorSet[1]}
            />
            <rect
              x={"66.666666%"}
              y={0}
              width={"66.8%"}
              height={"100%"}
              fill={colorSet[2]}
            />
          </g>
          <g>
            <rect
              x={"16.6666666667%"}
              y={"22%"}
              width={"66.6666666668%"}
              height={"6%"}
              fill={colorSet[3]}
            />
            <rect
              x={"16.6666666667%"}
              y={"47%"}
              width={"66.6666666668%"}
              height={"6%"}
              fill={colorSet[4]}
            />
            <rect
              x={"16.6666666667%"}
              y={"72%"}
              width={"66.6666666668%"}
              height={"6%"}
              fill={colorSet[5]}
            />
          </g>
        </svg>
      </div>
    </a>
  )
}

export default SvgMosaicGrid
