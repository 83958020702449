import React from "react"
import MethodButton from "./MethodButton"

const MethodSelect = props => (
  <div className='methods'>
    <div className='methods__content'>
      <MethodButton
        method='Random method'
        methodHandle={props.handleCreateRandomScale}
      />
      <MethodButton
        method='Any three colors'
        methodHandle={props.handleCreateAnyThreeScale}
      />
      <MethodButton
        method='Triad'
        methodHandle={props.handleCreateTriadScale}
      />
      <MethodButton
        method='Analogous'
        methodHandle={props.handleCreateAnalogousScale}
      />
      <MethodButton
        method='Comp. plus accent'
        methodHandle={props.handleCreateComplementariesAndAccentScale}
      />
    </div>
  </div>
)

export default MethodSelect
