import React from "react"

const SvgSingleColor = props => {
  const { x, y, width, height, lum, color } = props
  const backgroundColor = lum ? color[1] : color[0]
  return (
    <rect x={x} y={y} width={width} height={height} fill={backgroundColor} />
  )
}

export default SvgSingleColor
