import React, { useRef, useState, useEffect } from "react"
import { svgAsPngUri } from "save-svg-as-png"
import timestamp from "time-stamp"
import SaveIndicator from "./SaveIndicator"
import SvgSingleColor from "./SvgSingleColor"

const SvgSquareGrid = props => {
  const { className, colorSet, lum } = props
  const [p1, p2, p3, s1, s2, s3] = colorSet
  const grid = [
    [p3, s1, p2, s2, p1, s3],
    [s2, p1, s3, p3, s1, p2],
    [p1, s3, p2, s1, p3, s2],
    [s1, p2, s2, p3, s3, p1],
    [p3, s2, p1, s3, p2, s1],
    [s1, p2, s3, p1, s2, p3]
  ]

  const SvgElement = useRef(null)
  const [isHover, setHover] = useState(false)
  const [svgData, setSvgData] = useState("")
  const [id, setId] = useState("")
  const fileName = `square-grid-${id}.png`

  useEffect(() => {
    const svg = SvgElement.current
    svgAsPngUri(svg, "square-grid-scale.png").then(uri => {
      setSvgData(uri)
    })
  })

  const handleMouseEnter = () => setHover(true)
  const handleMouseLeave = () => setHover(false)
  const handleOnClick = () => {
    setId(`${timestamp("YYYY-MM-DD_HH-mm-ss")}`)
    setHover(false)
  }

  return (
    <a
      className='wrapper-link'
      href={svgData}
      download={fileName}
      onFocus={handleMouseEnter}
      onBlur={handleMouseLeave}
    >
      <div
        className={`color-grid ${className}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleOnClick}
      >
        <SaveIndicator visible={isHover} />
        <svg
          xmlns='http://www.w3.org/2000/svg'
          xmlnsse='http://svg-edit.googlecode.com'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          id='squareColorGrid'
          className='square-color-grid-svg'
          viewBox='0 0 500 500'
          preserveAspectRatio='xMidYMid meet'
          ref={SvgElement}
        >
          {grid.map((row, i) => (
            <g key={`g-${i}`}>
              {row.map((color, j) => (
                <SvgSingleColor
                  x={`${j * 16.6666666667}%`}
                  y={`${i * 16.6666666667}%`}
                  width='17%'
                  height='17%'
                  color={color}
                  key={`${i}-${j}`}
                  lum={lum}
                />
              ))}
            </g>
          ))}
        </svg>
      </div>
    </a>
  )
}

export default SvgSquareGrid
