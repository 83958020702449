import React from "react"

const MethodButton = props => {
  const { methodHandle, method, description } = props
  return (
    <button className='button' onClick={methodHandle}>
      <span className='button__method'>{method}</span>
      {description && (
        <span className='button__description'>{description}</span>
      )}
    </button>
  )
}

export default MethodButton
