import React from "react"
import SvgHorizontalGrid from "../components/SvgHorizontalGrid"

const SectionHorizontalScales = props => {
  const { scale, className } = props
  const scales = [
    {
      className: "h-main-scale",
      colorSet: [
        scale.p1.mainColor,
        scale.p2.mainColor,
        scale.p3.mainColor,
        scale.s1.mainColor,
        scale.s2.mainColor,
        scale.s3.mainColor
      ],
      lum: false,
      height: "80px",
      isFullWidth: true
    },
    {
      className: "h-first-primariy-dominant-scale",
      colorSet: [
        scale.p1.p1dom,
        scale.p2.p1dom,
        scale.p3.p1dom,
        scale.s1.p1dom,
        scale.s2.p1dom,
        scale.s3.p1dom
      ],
      lum: false,
      height: "80px",
      isFullWidth: false
    },
    {
      className: "h-second-primariy-dominant-scale",
      colorSet: [
        scale.p1.p2dom,
        scale.p2.p2dom,
        scale.p3.p2dom,
        scale.s1.p2dom,
        scale.s2.p2dom,
        scale.s3.p2dom
      ],
      lum: false,
      height: "80px",
      isFullWidth: false
    },
    {
      className: "h-third-primariy-dominant-scale",
      colorSet: [
        scale.p1.p3dom,
        scale.p2.p3dom,
        scale.p3.p3dom,
        scale.s1.p3dom,
        scale.s2.p3dom,
        scale.s3.p3dom
      ],
      lum: false,
      height: "80px",
      isFullWidth: false
    },
    {
      className: "h-first-secondary-dominant-scale",
      colorSet: [
        scale.p1.s1dom,
        scale.p2.s1dom,
        scale.p3.s1dom,
        scale.s1.s1dom,
        scale.s2.s1dom,
        scale.s3.s1dom
      ],
      lum: false,
      height: "80px",
      isFullWidth: false
    },
    {
      className: "h-second-secondary-dominant-scale",
      colorSet: [
        scale.p1.s2dom,
        scale.p2.s2dom,
        scale.p3.s2dom,
        scale.s1.s2dom,
        scale.s2.s2dom,
        scale.s3.s2dom
      ],
      lum: false,
      height: "80px",
      isFullWidth: false
    },
    {
      className: "h-third-secondary-dominant-scale",
      colorSet: [
        scale.p1.s3dom,
        scale.p2.s3dom,
        scale.p3.s3dom,
        scale.s1.s3dom,
        scale.s2.s3dom,
        scale.s3.s3dom
      ],
      lum: false,
      height: "80px",
      isFullWidth: false
    },
    {
      className: "h-all-colors",
      colorSet: scale.allColors,
      lum: false,
      height: "80px",
      isFullWidth: true
    }
  ]
  return (
    <section className={className}>
      {scales.map(({ className, colorSet, lum, height, isFullWidth }) => (
        <SvgHorizontalGrid
          key={className}
          className={className}
          colors={colorSet}
          lum={lum}
          height={height}
          isFullWidth={isFullWidth}
        />
      ))}
    </section>
  )
}

export default SectionHorizontalScales
