import React, { useState } from "react"

const BackgroundSelector = () => {
  const [backgroundColor, setBackgroundColor] = useState(
    localStorage.getItem("backgroundColor") || "white"
  )
  React.useEffect(() => {
    document.body.classList.add(`bg--${backgroundColor}`)
  }, [backgroundColor])

  const handleOnColorChange = e => {
    document.body.className = ""
    const backgroundColor = e.target.id
    setBackgroundColor(backgroundColor)
    document.body.classList.add(`bg--${backgroundColor}`)
    localStorage.setItem("backgroundColor", backgroundColor)
  }
  return (
    <div className='background-selector'>
      <button
        className='button button--bg-black'
        id='black'
        title='Black Background'
        onClick={handleOnColorChange}
      ></button>
      <button
        className='button button--bg-gray'
        id='gray'
        title='Gray Background'
        onClick={handleOnColorChange}
      ></button>
      <button
        className='button button--bg-white'
        id='white'
        title='White Background'
        onClick={handleOnColorChange}
      ></button>
    </div>
  )
}

export default BackgroundSelector
