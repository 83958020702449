export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// HSP Color Model
// sqrt(0.299 * R^2 + 0.587 * G^2 + 0.114 * B^2)
// http://alienryderflex.com/hsp.html
export const getLum = (color) => {
  const r = color.toRgb().r;
  const g = color.toRgb().g;
  const b = color.toRgb().b;
  return Math.round(
    Math.sqrt((0.299 * (r ** 2)) + (0.587 * (g ** 2)) + (0.114 * (b ** 2))),
  );
}

export const createArrayOfNumbers = length => [...Array(length).keys()];

export const randomAngleExcludingOffset = ({
  inRange: range, 
  removeAngle: angle, 
  withOffset: offset, 
  removeRollover = false
}) => {
  const isSillyRange = offset >= range/2 || offset < 0;
  if (isSillyRange) {
    throw Error("Invalid offset you silly!");
  }
  // Create array with the full range
  const fullRange = createArrayOfNumbers(range).map(i => i+1 );
  const offsetRange = offset*2;
  let rangeToRemove =[];

  // Remove each angle plus it's offset range
  angle.forEach((angle, i) => {
    // Create array with range to remove
    const currentRangeToRemove = createArrayOfNumbers(offsetRange+1).map((j) => {
      let currentNumber = j + angle - offset;
      /* If remove offset from start is true and there are numbers
      outside the range it will remove the range */
      if (removeRollover && currentNumber > range) {
        currentNumber = currentNumber % range;
      } else if (removeRollover && currentNumber <= 0){
        currentNumber += range;
      }
      return currentNumber
    } );
    /* Create array with range to remove and combine it with the 
    previous array */
    rangeToRemove = [...new Set([...rangeToRemove,...currentRangeToRemove])];
  });
  

  const newArray = fullRange.filter( number => !rangeToRemove.includes( number ) );

  const randomAngleInNewArray = newArray[getRandomInt(0, newArray.length)];

  return randomAngleInNewArray;
}