import React from "react"
import SvgGradientGrid from "../components/SvgGradientGrid"
const SectionGradientScales = props => {
  const { scale, className } = props

  const colors = scale.allColors

  const scales = [
    {
      className: "first-landscape",
      gradientScale: [
        [colors.slice(-1)[0], 0],
        [colors.slice(-3)[0], 40],
        [colors.slice(-5)[0], 65],
        [colors.slice(-5)[0], 75],
        [colors[4], 80],
        [colors[2], 82],
        [colors[0], 90]
      ]
    },
    {
      className: "second-landscape",
      gradientScale: [
        [colors.slice(-1)[0], 0],
        [colors.slice(-2)[0], 20],
        [colors.slice(-3)[0], 25],
        [colors[6], 30],
        [colors[4], 40],
        [colors[2], 70],
        [colors[0], 90]
      ]
    },
    {
      className: "third-landscape",
      gradientScale: [
        [colors.slice(-5)[0], 0],
        [colors.slice(-5)[0], 20],
        [colors.slice(-3)[0], 45],
        [colors.slice(-1)[0], 75],
        [colors[0], 78],
        [colors[0], 80],
        [colors[1], 85],
        [colors[3], 100]
      ]
    },

    {
      className: "primaries",
      gradientScale: [
        [scale.p1.mainColor, 0],
        [scale.p2.mainColor, 50],
        [scale.p3.mainColor, 100]
      ]
    },
    {
      className: "secondaries",
      gradientScale: [
        [scale.s1.mainColor, 0],
        [scale.s2.mainColor, 50],
        [scale.s3.mainColor, 100]
      ]
    },
    {
      className: "all-main",
      gradientScale: [
        [scale.p1.mainColor, 0],
        [scale.s1.mainColor, 16.6666666667 * 2],
        [scale.p2.mainColor, 16.6666666667 * 3],
        [scale.s2.mainColor, 16.6666666667 * 4],
        [scale.p3.mainColor, 16.6666666667 * 5],
        [scale.s3.mainColor, 16.6666666667 * 6]
      ]
    }
  ]

  return (
    <section className={className}>
      {scales.map(scale => (
        <SvgGradientGrid
          key={scale.className}
          className={scale.className}
          gradientScale={scale.gradientScale}
        />
      ))}
    </section>
  )
}

export default SectionGradientScales
