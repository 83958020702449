import React from "react"
import MaterialIcon from "material-icons-react"

const SaveIndicator = props => {
  const { visible } = props
  const className = visible ? "save-indicator--visible" : ""
  return (
    <span className={`save-indicator ${className}`}>
      <MaterialIcon icon='save_alt' color='#ffffff' invert />
    </span>
  )
}

export default SaveIndicator
