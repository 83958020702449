import React, { useRef, useState, useEffect } from "react"
import { svgAsPngUri } from "save-svg-as-png"
import timestamp from "time-stamp"
import SaveIndicator from "./SaveIndicator"
import SvgSingleColor from "./SvgSingleColor"

const SvgHorizontalGrid = props => {
  const { colors, lum, height, isFullWidth } = props

  const SvgElement = useRef(null)
  const [isHover, setHover] = useState(false)
  const [svgData, setSvgData] = useState("")
  const [id, setId] = useState("")
  const fileName = `horizontal-grid-${id}.png`

  useEffect(() => {
    const svg = SvgElement.current
    svgAsPngUri(svg, "horizontal-grid-scale.png").then(uri => {
      setSvgData(uri)
    })
  })

  const handleMouseEnter = () => setHover(true)
  const handleMouseLeave = () => setHover(false)
  const handleOnClick = () => {
    setId(`${timestamp("YYYY-MM-DD_HH-mm-ss")}`)
    setHover(false)
  }

  const colorsInScale = colors.length
  const singleColorWidth = 100 / colorsInScale

  const fullWidthClass = isFullWidth ? "wrapper-link--full-width" : ""

  return (
    <a
      className={`wrapper-link ${fullWidthClass}`}
      href={svgData}
      download={fileName}
      onFocus={handleMouseEnter}
      onBlur={handleMouseLeave}
    >
      <div
        className='color-grid horizontal-color-grid'
        style={{ height: height }}
        onClick={handleOnClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <SaveIndicator visible={isHover} />
        <svg
          xmlns='http://www.w3.org/2000/svg'
          xmlnsse='http://svg-edit.googlecode.com'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          id='horizontalColorGrid'
          className='horizontalColorGrid'
          viewBox='0 0 500 100'
          height={height}
          width='100%'
          preserveAspectRatio='none'
          ref={SvgElement}
        >
          <g width='100%'>
            {colors.map((color, i) => (
              <SvgSingleColor
                x={`${singleColorWidth * i}%`}
                y={`0`}
                width={`${Math.ceil(singleColorWidth)}%`}
                height='100%'
                color={color}
                key={`${i}`}
                lum={lum}
              />
            ))}
          </g>
        </svg>
      </div>
    </a>
  )
}

export default SvgHorizontalGrid
