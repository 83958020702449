import React from "react"

const ModalContent = props => {
  const { handleCloseModal } = props
  return (
    <div>
      <header className='modal__header'>
        <h1 className='title'>Color scales generator</h1>
        <h2 className='name'>by Guillermo Gineste</h2>
        <button
          className='button button--close-modal'
          onClick={handleCloseModal}
        >
          Close
        </button>
      </header>
      <p>
        This is a color scale generation tool for painters, illustrators and
        designers.
      </p>
      <p>
        I made this tool for two reasons: first to have a color tool that
        generates the kind of scales I want to use in my own images. Second, to
        practice creating a web application from scratch, if you can say using
        React is "from scratch".
      </p>
      <p>
        The algorithms behind each method are adjusted to create a specific kind
        of scale. Each scale has a mix of intentional and random weights for
        each value. This should prevent (too) extreme scales from happening.
      </p>

      <h3>Random method</h3>
      <p>Chose any method from bellow randomly.</p>
      <h3>Any three colors</h3>
      <p>Three random colors at three random points in the color wheel.</p>
      <h3>Triad</h3>
      <p>Three colors equidistant in the color wheel.</p>
      <h3>Analogous</h3>
      <p>Three colors close together</p>
      <h3>Comp. plus accent</h3>
      <p>
        Two complementary colors in opposite sides of the color wheel plus
        random accent.
      </p>
    </div>
  )
}

export default ModalContent
