import React, { useRef, useState, useEffect } from "react"
import { svgAsPngUri } from "save-svg-as-png"
import timestamp from "time-stamp"
import SaveIndicator from "./SaveIndicator"

const SvgGradientGrid = props => {
  const { className, gradientScale, height } = props

  const image = {
    w: 400,
    h: 500
  }

  const SvgElement = useRef(null)
  const [isHover, setHover] = useState(false)
  const [svgData, setSvgData] = useState("")
  const [id, setId] = useState("")
  const fileName = `gradient-grid-${id}.png`

  useEffect(() => {
    const svg = SvgElement.current
    svgAsPngUri(svg, "square-grid-scale.png").then(uri => {
      setSvgData(uri)
    })
  })

  const handleMouseEnter = () => setHover(true)
  const handleMouseLeave = () => setHover(false)
  const handleOnClick = () => {
    setId(`${timestamp("YYYY-MM-DD_HH-mm-ss")}`)
    setHover(false)
  }

  return (
    <a
      className='wrapper-link'
      href={svgData}
      download={fileName}
      onFocus={handleMouseEnter}
      onBlur={handleMouseLeave}
    >
      <div
        className='color-grid gradient-color-grid'
        style={{ height: height }}
        onClick={handleOnClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <SaveIndicator visible={isHover} />
        <svg
          xmlns='http://www.w3.org/2000/svg'
          xmlnsse='http://svg-edit.googlecode.com'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          id='horizontalColorGrid'
          className='gradient-color-grid-svg'
          viewBox={`0 0 ${image.w} ${image.h}`}
          height={height}
          width='100%'
          preserveAspectRatio='none'
          ref={SvgElement}
        >
          <defs>
            <linearGradient
              id={`gradient-${className}`}
              x1='0'
              x2='0'
              y1='0'
              y2='1'
            >
              {gradientScale.map((color, i) => {
                return (
                  <stop
                    className={`stop${i + 1}`}
                    key={i}
                    offset={`${color[1]}%`}
                    stopColor={color[0][0]}
                  />
                )
              })}
            </linearGradient>
          </defs>
          <rect
            id='rect1'
            x='0'
            y='0'
            width='100%'
            height='100%'
            fill={`url(#gradient-${className})`}
          />
        </svg>
      </div>
    </a>
  )
}

export default SvgGradientGrid
