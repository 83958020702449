import React, { useState } from "react"
import ReactModal from "react-modal"
import BackgroundSelector from "./BackgroundSelector"
import ModalContent from "./ModalContent"

const Header = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const handleOpenModal = () => {
    setModalIsOpen(true)
  }
  const handleCloseModal = () => {
    setModalIsOpen(false)
  }
  return (
    <header className='header'>
      <div className='header__content'>
        <h1 className='title'>Color scales generator</h1>
        <h2 className='name'>by Guillermo Gineste</h2>
        <BackgroundSelector />
        <button className='button button--modal' onClick={handleOpenModal}>
          <span>What is this?</span>
        </button>
        <ReactModal
          isOpen={modalIsOpen}
          contentLabel='onRequestClose Example'
          onRequestClose={handleCloseModal}
          shouldCloseOnOverlayClick={true}
          className='modal'
          overlayClassName='overlay'
        >
          <ModalContent handleCloseModal={handleCloseModal} />
        </ReactModal>
      </div>
    </header>
  )
}

export default Header
