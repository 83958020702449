import React from "react"
import SvgMosaicGrid from "../components/SvgMosaicGrid"

const SectionMosaicScales = props => {
  const { scale, className } = props
  const allColors = scale.allColors

  const scales = [
    {
      className: "lightest-and-darkest",
      colorSet: [
        allColors[1][0],
        allColors[0][0],
        allColors[2][0],
        allColors.slice(-2)[0][0],
        allColors.slice(-1)[0][0],
        allColors.slice(-3)[0][0]
      ]
    },
    {
      className: "lightest-and-darkest-inverted",
      colorSet: [
        allColors.slice(-2)[0][0],
        allColors.slice(-1)[0][0],
        allColors.slice(-3)[0][0],
        allColors[1][0],
        allColors[0][0],
        allColors[2][0]
      ]
    },
    {
      className: "lightest-and-darkest-step",
      colorSet: [
        allColors.slice(-3)[0][0],
        allColors.slice(-1)[0][0],
        allColors.slice(-4)[0][0],
        allColors[2][0],
        allColors[0][0],
        allColors[4][0]
      ]
    }
  ]

  return (
    <section className={className}>
      {scales.map(scale => (
        <SvgMosaicGrid
          key={scale.className}
          className={scale.className}
          colorSet={scale.colorSet}
          lum={false}
        />
      ))}
    </section>
  )
}

export default SectionMosaicScales
