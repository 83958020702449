import tinycolor from "tinycolor2"
import { getRandomInt, getLum, randomAngleExcludingOffset } from "./utils"

//TODO: Function that takes an array of colors and matches their luminosities
// const matchLum = () => {

// };

const MIN_SATURATION = 5
const MAX_SATURATION = 90
const MIN_LUMINOSITY = 15
const MAX_LUMINOSITY = 95

const createInitialColor = initialColor => {
  const randomP1 = tinycolor({
    h: getRandomInt(0, 360),
    s: getRandomInt(MIN_SATURATION, MAX_SATURATION),
    l: getRandomInt(MIN_LUMINOSITY, MAX_LUMINOSITY)
  })
  return initialColor ? tinycolor(initialColor) : randomP1
}

const setPrimaries = (p1color, p2color, p3color) => {
  const p1lum = getLum(p1color)
  const p2lum = getLum(p2color)
  const p3lum = getLum(p3color)

  return {
    p1: {
      mainColor: [p1color, tinycolor({ r: p1lum, g: p1lum, b: p1lum })]
    },
    p2: {
      mainColor: [p2color, tinycolor({ r: p2lum, g: p2lum, b: p2lum })]
    },
    p3: {
      mainColor: [p3color, tinycolor({ r: p3lum, g: p3lum, b: p3lum })]
    }
  }
}

export const createPrimariesWithNone = () => {
  let p1color = tinycolor("#1f4ed2")
  let p2color = tinycolor("#bc1f49")
  let p3color = tinycolor("#fffd00")

  return setPrimaries(p1color, p2color, p3color)
}

export const createPrimariesWithAnyThree = initialColor => {
  // 1. Create first primary. If an initialColor is given, use that one, else create a random one
  const p1color = createInitialColor(initialColor)
  const p1Hue = p1color.toHsl().h
  // 2. Create second primary.
  const p2Hue = randomAngleExcludingOffset({
    inRange: 360,
    removeAngle: [p1Hue],
    withOffset: 15,
    removeRollover: true
  })
  const p2color = tinycolor({
    h: p2Hue,
    s: getRandomInt(MIN_SATURATION, MAX_SATURATION),
    l: getRandomInt(MIN_LUMINOSITY, MAX_LUMINOSITY)
  })
  // 3. Create third primary.
  const p3Hue = randomAngleExcludingOffset({
    inRange: 360,
    removeAngle: [p1Hue, p2Hue],
    withOffset: 10,
    removeRollover: true
  })
  const p3color = tinycolor({
    h: p3Hue,
    s: getRandomInt(MIN_SATURATION, MAX_SATURATION),
    l: getRandomInt(MIN_LUMINOSITY, MAX_LUMINOSITY)
  })

  return setPrimaries(p1color, p2color, p3color)
}

export const createPrimariesAsTriad = initialColor => {
  const thirdOfColorWheel = 120
  const minColorPrecission = -10
  const maxColorPrecission = 10
  // 1. Create first primary. If an initialColor is given, use that one, else create a random one
  const p1color = createInitialColor(initialColor)
  // 2. Create second primary.
  const p1Hue = p1color.toHsl().h
  const p2Hue =
    (p1Hue +
      thirdOfColorWheel +
      getRandomInt(minColorPrecission, maxColorPrecission)) %
    360
  const p2color = tinycolor({
    h: p2Hue,
    s: getRandomInt(MIN_SATURATION, MAX_SATURATION),
    l: getRandomInt(MIN_LUMINOSITY, MAX_LUMINOSITY)
  })
  // 3. Create third primary.
  const p3Hue =
    p2Hue +
    thirdOfColorWheel +
    (getRandomInt(minColorPrecission, maxColorPrecission) % 360)
  const p3color = tinycolor({
    h: p3Hue,
    s: getRandomInt(MIN_SATURATION, MAX_SATURATION),
    l: getRandomInt(MIN_LUMINOSITY, MAX_LUMINOSITY)
  })
  return setPrimaries(p1color, p2color, p3color)
}

export const createPrimariesAsAnalogous = initialColor => {
  const minColorPrecission = -10
  const maxColorPrecission = 10
  const colorHueDistance = 20
  // 1. Create first primary. If an initialColor is given, use that one, else create a random one
  const p1color = createInitialColor(initialColor)
  // 2. Create second primary.
  const p1Hue = p1color.toHsl().h
  const p2Hue =
    (p1Hue +
      colorHueDistance +
      getRandomInt(minColorPrecission, maxColorPrecission)) %
    360
  const p2color = tinycolor({
    h: p2Hue,
    s: getRandomInt(MIN_SATURATION, MAX_SATURATION),
    l: getRandomInt(MIN_LUMINOSITY, MAX_LUMINOSITY)
  })
  // 3. Create third primary.
  const p3Hue =
    (p2Hue +
      colorHueDistance +
      getRandomInt(minColorPrecission, maxColorPrecission)) %
    360
  const p3color = tinycolor({
    h: p3Hue,
    s: getRandomInt(MIN_SATURATION, MAX_SATURATION),
    l: getRandomInt(MIN_LUMINOSITY, MAX_LUMINOSITY)
  })
  return setPrimaries(p1color, p2color, p3color)
}

export const createPrimariesAsComplementariesAndAccent = initialColor => {
  const oppositeInColorWheel = 180
  const minHuePrecission = -15
  const maxHuePrecission = 15
  // 1. Greate first primary. If an initialColor is given, use that one, else create a random one
  const p1color = createInitialColor(initialColor)
  // 2. Create second primary.
  const p1Hue = p1color.toHsl().h
  const p2Hue =
    (p1Hue +
      oppositeInColorWheel +
      getRandomInt(minHuePrecission, maxHuePrecission)) %
    360
  const p2color = tinycolor({
    h: p2Hue,
    s: getRandomInt(MIN_SATURATION, MAX_SATURATION),
    l: getRandomInt(MIN_LUMINOSITY, MAX_LUMINOSITY)
  })
  // 3. Create third primary.
  const p3Hue = randomAngleExcludingOffset({
    inRange: 360,
    removeAngle: [p1Hue, p2Hue],
    withOffset: 35,
    removeRollover: true
  })
  const p3color = tinycolor({
    h: p3Hue,
    s: getRandomInt(MIN_SATURATION, MAX_SATURATION),
    l: getRandomInt(MIN_LUMINOSITY, MAX_LUMINOSITY)
  })
  return setPrimaries(p1color, p2color, p3color)
}
