import React, { useState } from "react"
import "./App.css"
import { createScale } from "./scale-generator/generator"

import MethodSelect from "./components/MethodSelect"
import Header from "./components/Header"

// Sections
import SectionSquareScales from "./sections/SectionSquareScales"
import SectionHorizontalScales from "./sections/SectionHorizontalScales"
import SectionGradientScales from "./sections/SectionGradientScales"
import SectionMosaicScales from "./sections/SectionMosaicScales"

const App = () => {
  const [scale, setScale] = useState(createScale("none"))

  const handleCreateRandomScale = () => {
    setScale(createScale("random"))
  }
  const handleCreateAnyThreeScale = () => {
    setScale(createScale("anyThree"))
  }
  const handleCreateTriadScale = () => {
    setScale(createScale("triad"))
  }
  const handleCreateAnalogousScale = () => {
    setScale(createScale("analogous"))
  }
  const handleCreateComplementariesAndAccentScale = () => {
    setScale(createScale("compAndAccent"))
  }

  return (
    <div className='app'>
      <Header />
      <MethodSelect
        handleCreateRandomScale={handleCreateRandomScale}
        handleCreateAnyThreeScale={handleCreateAnyThreeScale}
        handleCreateTriadScale={handleCreateTriadScale}
        handleCreateAnalogousScale={handleCreateAnalogousScale}
        handleCreateComplementariesAndAccentScale={
          handleCreateComplementariesAndAccentScale
        }
      />
      <div className='main'>
        <SectionSquareScales
          className={"section section--square-scales"}
          scale={scale}
        />
        <SectionHorizontalScales
          className={"section section--horiztontal-scales"}
          scale={scale}
        />
        <SectionGradientScales
          className={"section section--gradient-scales"}
          scale={scale}
        />
        <SectionMosaicScales
          className={"section section--three-mosaic-scales"}
          scale={scale}
        />
      </div>
    </div>
  )
}

export default App
